import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterModule, NavigationEnd } from '@angular/router';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { CommonModule } from '@angular/common';
import { RefreshLoaderComponent } from './components/refresh-loader/refresh-loader.component';
import { AnalyticsService } from './services/analytics.service';
import { filter } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { ToastService } from './services/toast-service/toast.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterModule,
    CustomToastComponent,
    CommonModule,
    RefreshLoaderComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly toast = inject(ToastService);
  onlineEvent: Observable<Event> | undefined;
  offlineEvent: Observable<Event> | undefined;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string | undefined;
  connectionStatus: string | undefined;
  private readonly analyticsService = inject(AnalyticsService);

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        this.connectionStatusMessage = 'Back to online';
        this.connectionStatus = 'online';
        this.toast.onShow(
          'Success',
          this.connectionStatusMessage,
          true,
          'success'
        );
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.connectionStatusMessage =
          'Connection lost! You are not connected to internet';
        this.connectionStatus = 'offline';
        this.toast.onShow('Error', this.connectionStatusMessage, true, 'error');
      })
    );
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.analyticsService.page(undefined, event.urlAfterRedirects);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
